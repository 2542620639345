<template>
  <div>
    <van-search
      @cancel="onCancel"
      @clear="onClear"
      @search="onSearch"
      autofocus
      placeholder="请输入搜索关键词"
      shape="round"
      v-model="kw"
      show-action
    >
      <template #left>
        <span class="pv-10 pr-15" @click="onBack()">
          <van-icon name="arrow-left" size="20"></van-icon>
        </span>
      </template>
      <template #right-icon>
        <span @click="onSearch">搜索</span>
      </template>
    </van-search>

    <van-cell-group class="mt-10">
      <van-cell icon="search" title="搜索历史">
        <van-button @click="clearHistory" size="mini">清空</van-button>
      </van-cell>
      <van-cell v-if="searchHistory && searchHistory.length">
        <template v-for="item in searchHistory">
          <van-button
            :key="item"
            @click="onClickHistory(item)"
            class="mh-5 mb-5"
            plain
            size="small"
            square
            v-if="item"
          >{{item}}</van-button>
        </template>
      </van-cell>
      <van-cell title="无记录" title-class="p-30 tc" v-else></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from "vue";
import { Search, Row, Col } from "vant";
Vue.use(Search);
Vue.use(Row);
Vue.use(Col);

export default {
  name: "search",
  data() {
    return {
      cid: 5,
      kw: "",
      searchHistory: []
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.kw = route.query.kw || "";
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    catTree() {
      return this.$store.state.catTree || [];
    }
  },
  methods: {
    getSearchHistory() {
      this.searchHistory = this.getHistory();
    },
    clearHistory() {
      this.clrHistory();
      this.getSearchHistory();
    },
    onSearch() {
      let query = {
        sort: "newstime",
        order: 1,
        cid: this.cid * 1,
        page: 1
      };
      if (this.kw) {
        query.kw = this.kw;
        this.setHistory(this.kw);
      }
      this.$router.push({
        name: "goodsList",
        query
      });
    },
    onBack() {
      this.$router.back();
    },
    onCancel() {
      this.kw = "";
      this.onSearch();
    },
    onClear: function() {
      this.kw = this.$route.query.kw || "";
      this.$store.commit("setPageTitle", "搜索");
    },
    onClickHistory(kw) {
      this.kw = kw;
      this.onSearch();
    },
    changeCat(cat) {
      let query = {
        sort: "newstime",
        order: 1,
        cid: cat.classid,
        page: 1
      };
      if (this.kw) {
        query.kw = this.kw;
      }
      this.$router.push({
        name: "goodsList",
        query
      });
    }
  },
  created() {
    this.kw = this.$route.query.kw || "";
    this.getSearchHistory();
    this.$store.commit("setPageTitle", "搜索");
  },
  activated() {
    this.onBack();
    this.$store.commit("setPageTitle", "搜索");
  }
};
</script>

<style>
</style>
